<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fas fa-portrait"></i> ข้อมูลรายละเอียดผู้จัดการ
              </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="pb-4 text-right"></div>
          <div>
            <div class="card custom-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" style="font-weight: bold;"
                        >รหัสสั่งซื้อ :</label
                      >
                      <p>{{ item && item.code ? item.code : "-" }}</p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive padding_top_20">
                      <table class="table align-middle table-bordered">
                        <thead>
                          <tr class="text-center">
                            <th>ชื่อ</th>
                            <th>ดำเนินการ</th>
                            <th>วันที่</th>
                            <th>หมายเหตุ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in items"
                            :key="`list_order_view_${index}`"
                            class="text-center"
                          >
                            <td>{{ data.username }}</td>
                            <td>{{ getTextAction(data.action) }}</td>
                            <td>{{ data.log_date_short }}</td>
                            <td>{{ data.remark }}</td>
                          </tr>
                          <tr v-if="items.length === 0" class="text-center">
                            <td colspan="4">ไม่มีข้อมูล</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row padding_top_20">
                  <div
                    class="col-lg-1 col-md-2 col-3"
                    style="margin-top: 30px;"
                  >
                    <select
                      class="form-control mb-2"
                      id="pageLimit"
                      style="width: 80px;"
                      v-model="paging.limit"
                      @change="changeLimit()"
                    >
                      <option
                        v-for="data in paging.pages"
                        :key="`paging_${data}`"
                        :value="data"
                      >
                        {{ data }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-11">
                    <div class="row g-3" v-if="paging.rowTotal > 0">
                      <div
                        class="col-xl-11 col-lg-10 mb-2 text-right"
                        style="margin-top: 30px;"
                      >
                        <label
                          class="form-label"
                          style="margin: 8px; font-size: 12px;
                                font-family: Sarabun-Light;
                                color: #818181;"
                        >
                          แสดงรายการที่
                          <strong
                            >{{ (paging.page - 1) * paging.limit + 1 }} ถึง
                            {{ paging.page * paging.limit }}</strong
                          >
                          จาก
                          <strong
                            >{{ paging.rowTotal | numberIntFormat }} รายการ
                          </strong>
                        </label>
                        <paginate
                          v-model="paging.page"
                          :first-last-button="true"
                          :page-count="paging.pageTotal"
                          :page-range="5"
                          :margin-pages="2"
                          :prev-text="'&lt;'"
                          :next-text="'&gt;'"
                          :first-button-text="'&verbar;&lt;'"
                          :last-button-text="'&gt;&verbar;'"
                          :click-handler="changePage"
                          :container-class="
                            'pagination pagination-sm float-right m-auto paging_ul'
                          "
                          :page-class="'page-link'"
                          :prev-class="'page-link prev_paging'"
                          :next-class="'page-link next_paging'"
                        >
                        </paginate>
                      </div>
                      <div class="col-xl-1 col-lg-2 padding_left_none">
                        <label
                          class="form-label"
                          style="font-size: 12px;
                                font-family: Sarabun-Light;"
                        >
                          Go to page
                        </label>
                        <Select2
                          v-model="paging.page"
                          :options="paging.pageSizes"
                          @change="changePage(paging.page)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="float-right no_print">
              <span
                ><router-link :to="'/evaluate'" class="nav-link text-underline">
                  กลับ</router-link
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "../../../util/config";
export default {
  name: "evaluate-view-page-manager",
  data() {
    return {
      permission: {
        appSlug: "evaluate",
        actionData: {}
      },
      id: "",
      payment_id: "",
      delivery_id: "",
      item: {},
      items: [],
      paging: {
        page: 1,
        limit: Config.pagination.limit,
        pages: Config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      }
    };
  },
  methods: {
    changeLimit() {
      this.paging.page = 1;
      this.getListOrder();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getListOrder();
    },
    async getListOrder() {
      console.log(this.id, this.payment_id, this.delivery_id);
      this.SpinnerService.showLoader();
      let params = `page=${this.paging.page}&limit=${this.paging.limit}&payment_id=${this.payment_id}&delivery_id=${this.delivery_id}`;
      const result = await this.HttpServices.getData(
        `/ab_evaluate/getListLog/${this.id}?${params}`
      );
      if (result && result.status.code == "200") {
        const data = result.data;
        this.item = data.order;
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.pageTotal = total;
        this.paging.rowTotal = data.rowTotal;
        this.items = data.data;
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
        this.AlertUtils.alertCallback(
          "warning",
          `ไม่พบข้อมูลการสั่งซื้อ`,
          () => {
            this.$router.push("/evaluate");
          }
        );
      }
      this.SpinnerService.hideLoader();
    },
    getTextAction(action) {
      if (action == "create") {
        return "สร้าง";
      } else if (action == "update") {
        return "แก้ไข";
      } else if (action == "delete") {
        return "ลบ";
      } else if (action == "upload") {
        return "อัพโหลด";
      } else {
        return "-";
      }
    }
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );

    
    this.id = await this.SessionStorageUtils.getSession("page-evaluate-id");
    this.payment_id = await this.SessionStorageUtils.getSession("page-evaluate-payment-id");
    this.delivery_id = await this.SessionStorageUtils.getSession("page-evaluate-delivery-id");
    console.log(this.id)
      
    await this.getListOrder();
  }
};
</script>
